<template>
  <div class="nav" :class = "(showNavMovile)?'fixed-top': 'fixed-top box-shadow-bold'">
    <div v-if="!showAbrirCerrarMenu">
      <div @click="OpenNavMovile" class="expand-button">
        <div v-if="showNavMovile">
          Cerrar Menú
        </div>
        <div v-else>
          Abrir Menú
        </div>
      </div>
    </div>

    <div v-if="showAbrirCerrarMenu">
      <div class="dropdown-menus">
        <DropdownMenu button-text="Enlaces">
          <router-link to="/">Mi Cuernito<img src="@/assets/enlace.png"></router-link>
          <router-link to="/catalogo-panes">Catálogo de panes<img src="@/assets/enlace.png"></router-link>
          <router-link to="/oferta-trabajo">Oferta de trabajo <img src="@/assets/enlace.png"></router-link>
          <router-link to="/localizaciones">Localizaciones <img src="@/assets/enlace.png"></router-link>
          <router-link to="/sobre-nosotros">Sobre nosotros <img src="@/assets/enlace.png"></router-link>
        </DropdownMenu>
        <DropdownMenu button-text="Redes">
          <a href="#">Facebook  <img src="@/assets/facebook.png"></a>
          <a href="#">Instagram <img src="@/assets/instagram.png"></a>
          <a href="#">Youtube <img src="@/assets/youtube.png"></a>
        </DropdownMenu>
        <DropdownMenu button-text="Slogan">
          <a href="#" style="font-style:italic"> Tan dulces por dentro como tú. </a>
        </DropdownMenu>
      </div>
    </div>

    <div class="nav-logo-container">
      MI CUERNITO
      <div class="nav-logo">
        <img src="@/assets/cuernito.png" alt="logo">
      </div>
    </div>
  </div>

  <transition>
    <NavbarMovile v-if="showNavMovile"></NavbarMovile>
  </transition>

</template>

<script>
import NavbarMovile from './NavbarMovileComponent.vue'
import DropdownMenu from './DropdownMenuComponent.vue'

export default {
  props: {
    // Define the prop and its type
    movile: Boolean,
    test: String
  },
  name: 'NavbarComponent',
  components: {
    NavbarMovile,
    DropdownMenu
  },
  data () {
    return {
      showAbrirCerrarMenu: false,
      showNavMovile: false,
      minWidthForWideScreen: 500
    }
  },
  mounted () {
    this.showAbrirCerrarMenu = window.innerWidth >= this.minWidthForWideScreen
    window.addEventListener('resize', this.handleResize)
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    OpenNavMovile () {
      this.showNavMovile = !this.showNavMovile
    },
    handleResize () {
      // Actualiza el estado de isWideScreen cuando se redimensiona la ventana
      this.showAbrirCerrarMenu = window.innerWidth >= this.minWidthForWideScreen
    }
  }
}
</script>

<style scoped>
.fixed-top {
  position: fixed;
  z-index:12;
}

.helper {
  position: absolute;
  z-index:10;
}
.nav {
  display: flex;
  width: calc(100% - 2rem) ;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1rem;
  font-size: 1.2rem;
  background-color: white;
}
.box-shadow-light {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 24px;
}
.box-shadow-bold {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 24px;
}
.nav-logo {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-logo img {
  width: 100%;
  margin-block:auto;
}
.nav-logo-container {
  padding-block: 1rem;
  display:flex;
  justify-content:center;
  align-items:center;
  gap: 0.4rem;
  font-size:1.4rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #47321d;
}
.nav-logo-container:hover {
  cursor:pointer;
  color: #ac7c4d;
}
.expand-button {
  font-size:1rem;
  color: #664829;
}
.expand-button:hover {
  cursor: pointer;
}
.v-enter-active, .v-leave-active {
  transition: transform 0.5s ease; /* Duración y función de transición */
}
.v-enter-from, .v-leave-to {
  transform: translateX(-100%); /* Mueve el elemento fuera de la vista hacia la izquierda */
}

/* Large screens styles */
@media (width > 500px) {
  .nav {
    width: calc(100% - 16%);
    padding-inline: 8%;
    padding-block: 0;
  }
  .nav-logo-container {
    padding-block: 2rem;
  }
  .dropdown-menus {
    display: flex;
  }
}
</style>
