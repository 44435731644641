<template>
  <HeroComponent />
  <OpinionsComponent />
</template>

<script>
import HeroComponent from '../components/HeroComponent.vue'
import OpinionsComponent from '../components/OpinionsComponent.vue'

export default {
  components: {
    HeroComponent,
    OpinionsComponent
  }
}
</script>
