<template>
  <div class="hero-content-wrapper">
    <div class="hero-slides-wrapper">
        <div class="content-wrapper slide1">
          <div class="eslogan-hero">
              <div>
                  Mi Cuernito...
              </div>
            </div>
            <div class="img-wrapper">
              <img src="@/assets/cuernito-hero.png" alt="">
            </div>
        </div>
        <div class="content-wrapper slide2">
            <div class="eslogan-hero">
              <div>
                  Solo con una mordida...
              </div>
            </div>
            <div class="img-wrapper">
              <img src="@/assets/cuernito-hero.png" alt="">
            </div>
        </div>
        <div class="content-wrapper slide3">
          <div class="eslogan-hero">
            <div>
                Te lleva al paraíso...
            </div>
          </div>
          <div class="img-wrapper">
            <img src="@/assets/cuernito-hero.png" alt="">
          </div>
        </div>
      </div>
      <div class="hero-carrusel-wrapper" >
        <div class="carrusel-btns-wrapper">
          <div @click="carruselBtn(1)" class="carrusel-btn"></div>
          <div @click="carruselBtn(2)" class="carrusel-btn"></div>
          <div @click="carruselBtn(3)" class="carrusel-btn"></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      position: 1
    }
  },
  methods: {
    carruselBtn (i) {
      this.position = i
      const slidesWrapper = document.querySelector('.hero-slides-wrapper')
      const operacion = (i - 1) * -33.3
      slidesWrapper.style.transform = `translateX(${operacion}%)`
    }
  }
}
</script>

<style scoped>
  .slide1 {
    background-color: rgb(255, 251, 243);
  }
  .hero-content-wrapper {
    background-color:rgb(65, 65, 31);
    overflow: hidden;
    width: 100%;
    height: 70vh;
    position: relative;
    z-index: 5;
  }
  .hero-slides-wrapper {
    display:flex;
    width: 300vw;
    position:absolute;
    z-index: 0;
    transition: transform 0.5s ease;
  }
  .slide1 {
    background: rgb(255, 233, 183);
    background: radial-gradient(circle, rgb(248, 229, 143) 15%, rgb(209, 139, 73) 100%);
  }
  .slide2 {
    background: rgb(233, 194, 160);
    background: radial-gradient(circle, rgb(255, 240, 212) 15%, rgb(209, 102, 73) 100%);
  }
  .slide3 {
    background: rgb(187, 195, 253);
    background: radial-gradient(circle, rgb(196, 205, 240) 15%, rgb(117, 72, 224) 100%);
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 100%;
    font-weight: bold;
  }
  .img-wrapper {
    width: 15rem;
    margin-top: 3rem;
  }
  .content-wrapper .img-wrapper img {
    width: 100%;
  }
  .eslogan-hero {
    text-align: center;
    font-family:  'Arial Narrow', Arial, sans-serif;
    color: #382817;
    padding-inline: 1.5rem;
    margin-top: 6rem;
  }
  .eslogan-hero div {
    font-size: 2.2rem;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .hero-carrusel-wrapper {
    height: 70vh;
    position: absolute;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .carrusel-btns-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 0.9rem;
    justify-content: center;
  }
  .carrusel-btn {
    width: 7px;
    height: 7px;
    border: 1px solid rgb(252, 249, 242);
    border-radius: 100%;
  }
  .carrusel-btn:hover {
    cursor: pointer;
    background-color: rgb(240, 200, 115);
  }
</style>
