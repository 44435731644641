<template>
  <div class="dropdown-menu">
    <div class="dropdown-menu-btn" @click="toggleMenu">
      <slot name="menu-btn">{{ buttonText }}</slot>
      <img src="@/assets/abajo.png" width="8px">
    </div>
    <transition name="bounce">
      <div class="drop-down-elements-wrapper" v-if="isOpen">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: 'DropdownMenu'
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style>

.drop-down-elements-wrapper {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top:0.8rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 0px;
  }
  .drop-down-elements-wrapper a {
    color: #a47240;
    text-decoration: none;
    padding-block: 1rem;
    font-size: 1.2rem;
    padding-inline:1rem;
    background-color: white;
    font-style: oblique;
  }
  .drop-down-elements-wrapper a:hover {
    color: #da9958;
    background-color: #faf0e6;
    cursor: pointer;
  }
  .drop-down-elements-wrapper a img {
    width: 0.8rem;
    padding-left: 0.2rem;
  }
  .dropdown-menu-btn {
    padding-inline:0.5rem;
    color: #664829;
    font-style:oblique;
  }
  .dropdown-menu-btn img {
    padding-left: 5px;
  }
  .dropdown-menu-btn:hover {
    cursor:pointer;
    color: #da9958;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .2s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: translateY(-1rem);
    }
    100% {
      transform: translateY(0);
    }
}

</style>
