<template>
  <NavbarComponent />
  <router-view/>
  <FooterComponent />
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    NavbarComponent,
    FooterComponent
  }
}
</script>

<style>
  body {
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: 'Times New Roman', Times, serif;
  }
  /* Para navegadores basados en WebKit como Chrome y Safari */
  ::-webkit-scrollbar {
    width: 5px; /* Cambia el ancho de la barra de desplazamiento */
  }
  /* Estilos adicionales para la barra de desplazamiento */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color de fondo de la pista de la barra de desplazamiento */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del pulgar de la barra de desplazamiento */
    border-radius: 5px; /* Radio de borde del pulgar */
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del pulgar de la barra de desplazamiento al pasar el mouse */
  }
</style>
