<template>

  <div class="footer-wrapper">

    <div class="links-wrapper">
      <a href="#">Facebook  <img src="@/assets/facebook.png"></a>
      <a href="#">Instagram <img src="@/assets/instagram.png"></a>
      <a href="#">Youtube <img src="@/assets/youtube.png"></a>
    </div>

    <div class="links-wrapper">
        <router-link to="/">Mi Cuernito<img src="@/assets/enlace.png"></router-link>
        <router-link to="/catalogo-panes">Catálogo de panes<img src="@/assets/enlace.png"></router-link>
        <router-link to="/oferta-trabajo">Oferta de trabajo <img src="@/assets/enlace.png"></router-link>
        <router-link to="/localizaciones">Localizaciones <img src="@/assets/enlace.png"></router-link>
        <router-link to="/sobre-nosotros">Sobre nosotros <img src="@/assets/enlace.png"></router-link>
    </div>

    <div class="footer-logo-wrapper">
      <div class="img-logo-wrapper">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="text-logo-wrapper">MI CUERNITO</div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>

.links-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: 2rem;
  align-items: center;
  justify-content: center;
  gap:1rem;
}
.links-wrapper a {
  color: #ddd;
  text-decoration: none;
  font-size: 1rem;
}
.links-wrapper a img {
  padding-left:8px;
  width: 0.8rem;
}

.footer-wrapper {
  background-color: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 3rem;
}

.footer-logo-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  padding: 2rem 1rem;
}
.text-logo-wrapper {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ddd;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: oblique;
}
.img-logo-wrapper {
  width: 2.5rem;
}
.img-logo-wrapper img {
  width: 100%;
}

@media (width > 700px) {
  .footer-wrapper{
    flex-direction: row-reverse;
    gap: 10%;
  }
  .links-wrapper{
    width: fit-content;
    align-items: end;
  }
  .text-logo-wrapper {
    font-size: 2rem;
  }
  .img-logo-wrapper {
    width: 3.5rem;
  }
}

</style>
