import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CatalogoPanesView from '../views/CatalogoPanesView.vue'
import OfertaTrabajoView from '../views/OfertaTrabajoView.vue'
import LocalizacionesView from '../views/LocalizacionesView.vue'
import SobreNosotrosView from '../views/SobreNosotrosView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/catalogo-panes',
    name: 'catalogoPanes',
    component: CatalogoPanesView
  },
  {
    path: '/oferta-trabajo',
    name: 'ofertaTrabajo',
    component: OfertaTrabajoView
  },
  {
    path: '/localizaciones',
    name: 'localizaciones',
    component: LocalizacionesView
  },
  {
    path: '/sobre-nosotros',
    name: 'sobreNosotros',
    component: SobreNosotrosView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
