<template>
  <div class="jobs-container">
    <div class="fixed-cuernito fxd1">
      <img src="@/assets/cuernito.png" alt="">
    </div>
    <div class="jobs-img-overlay">
      <div class="float-cuernito fc1">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="float-cuernito fc2">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="float-cuernito fc3">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="float-cuernito fc4">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="float-cuernito fc5">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="job-container">
      <div class="job-title">
        Panadero
      </div>
      <div class="job-header">
        Descripción del puesto:
      </div>
      <div class="job-span">
        Preparar los panes.
      </div>
      <div class="job-header">
        Horario:
      </div>
      <div class="job-span">
        L-V de 9am a 5pm
      </div>
    </div>
    <div class="job-container">
      <div class="job-title">
        Cajero
      </div>
      <div class="job-header">
        Descripción del puesto:
      </div>
      <div class="job-span">
        Atender la caja.
      </div>
      <div class="job-header">
        Horario:
      </div>
      <div class="job-span">
        L-V de 9am a 5pm
      </div>
    </div>
    <div class="job-container">
      <div class="job-title">
        Repartidor
      </div>
      <div class="job-header">
        Descripción del puesto:
      </div>
      <div class="job-span">
        Entregar pedidos a domicilios ya sea en moto o en camión
      </div>
      <div class="job-header">
        Horario:
      </div>
      <div class="job-span">
        L-V de 9am a 5pm
      </div>
    </div>
    <div class="job-container">
      <div class="job-title">
        Miembro del equipo de logística.
      </div>
      <div class="job-header">
        Descripción del puesto:
      </div>
      <div class="job-span">
        Entregar pedidos a domicilios ya sea en moto o en camión
      </div>
      <div class="job-header">
        Horario:
      </div>
      <div class="job-span">
        L-V de 9am a 5pm
      </div>
    </div>
    </div>
  </div>
</template>

<style>
  .fixed-cuernito {
    position: fixed;
    display:flex;
    justify-content: center;
    opacity: 0.1;
    width: 100%;
  }
  .fixed-cuernito img {
    width: 20rem;
    height: 15rem;
  }
  .fc1 {
    top: 30%;
    left: 5%;
    width: 3.5rem;
    transform: rotate(20deg);
  }
  .fc2 {
    top: 60%;
    left: 10%;
    width: 2rem;
    transform: rotate(33deg);

  }
  .fc3 {
    top: 10%;
    right: 7%;
    width: 4rem;
    transform: rotate(123deg);
  }
  .fc4 {
    bottom: 2%;
    right: 5%;
    width: 3rem;
    transform: rotate(20deg);
  }
  .fc5 {
    top: 50%;
    right: 10%;
    width: 4rem;
    transform: rotate(180deg);
  }
  .float-cuernito {
    opacity: 0.9;
    position: absolute;
    z-index: -1;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  .float-cuernito img {
    width: 100%;
  }
  .jobs-img-overlay {
    position: relative;
  }
  .jobs-container {
    padding-top: 7rem;
    padding-bottom: 2rem;
    background-color:rgba(255, 235, 203, 0.1);
    position: relative;
  }
  .job-container {
    text-align: center;
    padding-block: 1.5rem;
    color: rgb(27, 27, 27);
    padding-inline: 1rem;
  }
  .job-title {
    font-style:italic;
    font-weight: bold;
    scale: 1 .90;
    font-size: 1.5rem;
  }
  .job-header {
    font-size: 1.3rem;
    scale: 1 .90;
  }
  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }
  @media (width > 600px) {
  .fixed-cuernito img {
    width: 30rem;
    height: 25rem;
  }
  .fc1 {
    top: 30%;
    left: 5%;
    width: 4.5rem;
    transform: rotate(20deg);
  }
  .fc2 {
    top: 60%;
    left: 10%;
    width: 3rem;
    transform: rotate(33deg);
  }
  .fc3 {
    top: 10%;
    right: 7%;
    width: 5rem;
    transform: rotate(123deg);
  }
  .fc4 {
    bottom: 2%;
    right: 5%;
    width: 4rem;
    transform: rotate(20deg);
  }
  .fc5 {
    top: 50%;
    right: 10%;
    width: 5rem;
    transform: rotate(180deg);
  }
  }
  @media (width > 1000px) {
    .fc1 {
      width: 7rem;
      left: 20%
    }
    .fc2 {
      width: 9rem;
    }
    .fc3 {
      width: 7rem;
      right: 20%
    }
    .fc4 {
      width: 4rem;
      right: 25%

    }
    .fc5 {
      width: 7rem;
      right: 10%
    }
  }
</style>
