<template>
  <div class="navbar-movile">
    <div class="links-wrapper">
      <router-link to="/">Mi Cuernito<img src="@/assets/enlace.png"></router-link>
      <router-link to="/catalogo-panes">Catálogo de panes<img src="@/assets/enlace.png"></router-link>
      <router-link to="/oferta-trabajo">Oferta de trabajo <img src="@/assets/enlace.png"></router-link>
      <router-link to="/localizaciones">Localizaciones <img src="@/assets/enlace.png"></router-link>
      <router-link to="/sobre-nosotros">Sobre nosotros <img src="@/assets/enlace.png"></router-link>
    </div>
    <div class="redes-wrapper">
      Nuestras redes:
      <a href="#"><img src="@/assets/facebook.png" alt=""></a>
      <a href="#"><img src="@/assets/instagram.png" alt=""></a>
      <a href="#"><img src="@/assets/youtube.png" alt=""></a>
    </div>
    <div class="slogan-nav-movile">
      <div>
        Tan dulces por dentro como tú.
      </div>
    </div>
  </div>
</template>

<style scoped>
.helpler {
  width: 100vw;
  height: 100vh;
}
.slogan-nav-movile {
  font-size: 1.2rem;
  font-style: italic;
  bottom: 2rem;
  margin-inline: 1rem;
  position:absolute;
}
.redes-wrapper {
  border-bottom: 1px solid black;
  display: flex;
  gap: 1rem;
  width: calc(100% - 2rem);
  padding-block: 2rem;
  padding-inline: 1rem;

  color: #a47240;
  font-size: 1.2rem;
  font-weight: bold;
}
.redes-wrapper a img {
  width: 100%;
}
.redes-wrapper a {
  width: 1.5rem;
}
.links-wrapper {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  padding-block: 2rem;
  width: fit-content;
  width: 100%;
}
.links-wrapper a img {
  width: 0.8rem;
}
.navbar-movile .link:hover {
  background-color: #faf0e6;
  cursor: pointer;
}
.navbar-movile {
  height: calc(100vh - 3rem);
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 10;
  top: 3rem;
}
.navbar-movile .links-wrapper a {
  color: #a47240;
  letter-spacing: 0.09rem;
  padding-inline: 1rem;
  padding-block:1rem;
  font-size: 1.1rem;
  text-decoration: none;
  text-transform: uppercase;
}
.navbar-movile .links-wrapper a:hover {
  background-color: #ffefdf;
}
</style>
