<template>
  <div style="padding-top: 5rem">
    <div class="about-us-wrapper">
      <div class="cuernito">
        <img src="@/assets/cuernito.png" alt="">
      </div>
      <div class="cacaos">
        <img src="@/assets/granosCacao.png" alt="">
      </div>
      <h2>
        Nuestra Historia
      </h2>
      <p>
        En el corazón de la bulliciosa ciudad de México, se encuentra la panadería "El Rincón del Chocolate". Fundada hace más de tres décadas por la apasionada panadera María, esta pequeña panadería se ha convertido en un punto de referencia para los amantes del chocolate en la región.
      </p>
      <p>
        María, una talentosa panadera con un amor insaciable por el chocolate desde una edad temprana, decidió abrir su propia panadería después de años de perfeccionar sus recetas en su modesta cocina. Inspirada por los cuernitos de chocolate que solía disfrutar de niña, decidió hacerlos el sello distintivo de su negocio.
      </p>
      <p>
        Con el tiempo, los cuernitos de chocolate de María se hicieron famosos en toda la ciudad. Su secreto radicaba en la calidad de los ingredientes que utilizaba: cacao puro, mantequilla fresca y una pizca de cariño en cada horneada. Los clientes hacían fila desde temprano en la mañana para conseguir sus cuernitos recién horneados, y pronto, la panadería se convirtió en un punto de encuentro para la comunidad local.
      </p>
    </div>
    <div class="list-wrapper">
      <div class="auxiliar-wrapper">
        <div class="auxiliar-wrapper2">
          <h2>
            Nuestro Pan
          </h2>
          <ul>
            <li>Orgullos de que nuestros ingredientes sean 100% Mexicanos.</li>
            <li>Cacao de Tamaulipas de las selvas más frondosas del lugar.</li>
            <li>Control de calidad de varios organos gubernamentales.</li>
            <li>Aunque nuestro mayor orgullo son los cuernitos, tenemos más variedad de panes con la misma calidad de producción y amor.</li>
          </ul>
        </div>
        <div class="img-wrapper">
          <img src="@/assets/CuernitoFact.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .cacaos {
    position:absolute;
    opacity: 0.4;
    top:0;
    right:0;
    width: 120px;
  }
  .cacaos img {
    width: 100%;
  }
  .auxiliar-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .img-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 5px 1px rgba(43, 43, 43, 0.4);
    padding-inline: 0.5rem;
    min-width: 250px;
  }
  .img-wrapper img {
    width: 100%;
  }
  .cuernito {
    position: absolute;
    width: 20rem;
    opacity: 0.2;
    bottom:0;
    right: 0;
  }
  .cuernito img {
    width: 100%;
  }
  .list-wrapper {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-inline: 1rem;
    padding-block: 2rem;
  }
  .list-wrapper h2 {
    color: rgb(83, 58, 1);
  }
  .list-wrapper ul {
    padding-left: 1rem;
    margin: 0;
  }
  .list-wrapper li {
    padding-bottom: 0.5rem;
  }
  .about-us-wrapper {
    position: relative;
    color: rgb(245, 245, 245);
    background-color: rgba(46, 32, 0, 0.827);
    text-align: start;
    padding-inline: 1rem;
    padding-block: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  @media (width > 500px) {
    .cacaos {
      right: unset;
      width:300px;
      left:0;
      top:-2rem;
      opacity: 0.2;
    }
    .cuernito {
      width: 25rem;
    }
    .about-us-wrapper {
      padding-inline: 10%;
      padding-block: 3rem;
    }
    .list-wrapper {
      padding-inline: 7%;
    }
  }
  @media (width > 660px) {
    .auxiliar-wrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    .auxiliar-wrapper2 {
      flex: 2;
    }
  }
</style>
