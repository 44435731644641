<template>
  <div class="content">
    <div class="tabs-wrapper">
      <div @click="setCuernitos" class="tap-wrapper">
        Cuernitos
      </div>
      <div @click="setDonas" class="tap-wrapper">
        Donas
      </div>
      <div @click="setConchas" class="tap-wrapper">
        Conchas
      </div>
    </div>
    <div class="menu-wrapper">
      <div v-if="currentPan == 'cuernitos'" class="panes-grid-wrapper">
        <div class="pan-wrapper" v-for="(cuernito, index) in cuernitosArray" :key="index">
          <div class="img-wrapper">
            <img :src=cuernito.img alt="">
          </div>
          <div class="text-wrapper">
            <div class="title-wrapper">
              {{ cuernito['title'] }}
            </div>
            <div class="description-wrapper">
              {{ cuernito['descripcion'] }}
            </div>
          </div>
          <div class="actions-wrapper">
            <div @click="addToCard(cuernito.title, cuernito.precio)" class="add-button-wrapper">
              <div class="price">${{ cuernito['precio'] }}</div> <div class="btn-text">Añadir al carrito</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentPan == 'donas'" class="panes-grid-wrapper">
        <div class="pan-wrapper" v-for="(dona, index) in donasArray" :key="index">
          <div class="img-wrapper">
            <img :src=dona.img alt="">
          </div>
          <div class="text-wrapper">
            <div class="title-wrapper">
              {{ dona['title'] }}
            </div>
            <div class="description-wrapper">
              {{ dona['descripcion'] }}
            </div>
          </div>
          <div class="actions-wrapper">
            <div @click="addToCard(dona.title, dona.precio)" class="add-button-wrapper">
              <div class="price">${{ dona['precio'] }}</div> <div class="btn-text">Añadir al carrito</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isOpenCarrito" class="carrito-wrapper">
        <div @click="closeCarrito" class="carrito-btn-close">
          Cerrar
        </div>
        <div class="carrito-title-wrapper">
          Tu carrito
        </div>
        <div class="carrito-total-wrapper">
          <div class="carrito-total-text">
            Costo del carrito
          </div>
          <div class="carrito-total-amount">
            ${{ getTotal() }}
          </div>
        </div>
        <div class="carrito-list-wrapper">
          <div v-for="(item, index) in carritoArray" :key="index" class="carrito-item-wrapper">
            <div class="carrito-item-text">
              {{ item.name }} x{{ item.amount }}
            </div>
            <div class="carrito-item-amount">
              ${{ item.price * item.amount }}
            </div>
          </div>

        </div>
        <div class="carrito-pago-wrapper">
          Proceder a pagar
        </div>
      </div>
    </div>
    <div @click="openCarrito" class="open-carrito-wrapper">
      Abrir carrito
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isOpenCarrito: false,
      currentPan: 'cuernitos',
      carritoArray: [
      ],
      cuernitosArray: [
        {
          title: 'Cuernito de Chocolate',
          descripcion: 'Clásico cuernito de chocolate cacao 100% natural',
          img: require('@/assets/cuernito-hero.png'),
          precio: 22
        },
        {
          title: 'Cuernito de Manzana',
          descripcion: 'Clásico cuernito de puré de manzana',
          img: require('@/assets/cuernito-hero.png'),
          precio: 23
        },
        {
          title: 'Cuernito de Piña',
          descripcion: 'Clásico cuernito de puré de piña',
          img: require('@/assets/cuernito-hero.png'),
          precio: 23.4
        },
        {
          title: 'Cuernito de Arandanos',
          descripcion: 'Clásico cuernito de puré de arandano',
          img: require('@/assets/cuernito-hero.png'),
          precio: 20
        }
      ],
      donasArray: [
        {
          title: 'Dona de chispas',
          descripcion: 'Una dona azucarada',
          img: require('@/assets/dona.png'),
          precio: 22
        }
      ]
    }
  },
  methods: {
    openCarrito () {
      this.isOpenCarrito = true
    },
    closeCarrito () {
      this.isOpenCarrito = false
    },
    setCuernitos () {
      this.currentPan = 'cuernitos'
    },
    setDonas () {
      this.currentPan = 'donas'
    },
    addToCard (name, price) {
      const index = this.carritoArray.findIndex(item => item.name === name)
      if (index !== -1) {
        // Si el artículo ya está en la lista, incrementa la cantidad
        this.carritoArray[index].amount++
      } else {
        // Si el artículo no está en la lista, agrégalo con cantidad 1
        this.carritoArray.push({
          name: name,
          price: price,
          amount: 1
        })
      }
    },
    getTotal () {
      return this.carritoArray.reduce((total, item) => total + item.price * item.amount, 0)
    }
  }
}
</script>

<style scoped>
  .open-carrito-wrapper {
    position: fixed;
    bottom: 0;
    margin-top:auto;
    background-color: rgb(55, 43, 158);
    padding: 0.3rem;
    text-align: center;
    color: #fff;
    width: 100%;
  }
  .open-carrito-wrapper:hover {
    cursor:pointer
  }
  .carrito-item-wrapper {
    display:flex;
    padding-inline:0.2rem;
    justify-content:space-between;
    color: #3d0303;
    gap:1rem;
  }
  .carrito-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 30;
    top: 0;
    display: flex;
    flex-direction:column;
    background-color: white;
  }
  .carrito-btn-close {
    color:blue;
    text-align: end;
    padding:1rem;
    font-size: 0.9rem;
  }
  .carrito-btn-close:hover {
    cursor:pointer;
  }
  .carrito-title-wrapper{
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.4rem;
    padding-bottom:2rem;
  }
  .carrito-total-wrapper {
    display:flex;
    margin-inline:0.5rem;
    padding-inline:0.2rem;
    padding-block: 1rem;
    justify-content:space-between;
    color: #3d0303;
    border-bottom: 1px solid #888888;
    border-top: 1px solid #888888;
  }
  .carrito-list-wrapper {
    margin-inline: 0.5rem;
    padding-block:1rem;
    display:flex;
    flex-direction:column;
    gap:1rem;
  }
  .carrito-pago-wrapper {
    margin-top:auto;
    background-color: rgb(43, 158, 43);
    padding: 0.5rem;
    text-align: center;
    color: #fff;
  }
  .menu-wrapper {
    display:flex;
    justify-content: center;
  }
  .tabs-wrapper {
    padding: 0.5rem;
    display:flex;
    background-color: green;
    padding-left: 7%;
  }
  .tap-wrapper {
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: bold;
  }
  .tap-wrapper:hover {
    cursor:pointer;
    color: #fec184;
  }
  .content {
    padding-top: 6rem;
  }
  .pan-wrapper {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /*width: calc(50% - 0.2rem);*/
    width: 200px;
    display: flex;
    flex-direction: column;
  }
  .pan-wrapper:hover {
    box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
    cursor: pointer;
  }
  .img-wrapper {
    min-width: 125px;
    max-width: 200px;
    padding: 0.5rem;
  }
  .img-wrapper img {
    width: 100%;
  }
  .text-wrapper {
    padding: 0.5rem;
  }
  .actions-wrapper {
    padding: 0.5rem;
    margin-top: auto;
  }
  .title-wrapper {
    font-weight: bold;
    padding-bottom: 1rem;
  }
  .panes-grid-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    padding: 0.5rem;
    gap:0.2rem;
  }
  .add-button-wrapper {
    background-color: rgb(43, 158, 43);
    padding: 0.5rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }
  .add-button-wrapper:hover {
    color: #fdd475;
  }
  @media (width > 320px) {
    .pan-wrapper {
      min-width: 250px;
    }
    .img-wrapper {
      max-width: 200px;
      margin-inline:auto;
    }
  }
  @media (width > 550px) {
    .menu-wrapper {
      justify-content: flex-start;
    }
    .panes-grid-wrapper {
      justify-content: flex-start;
    }
    .carrito-wrapper {
      width: 30%;
      right: 0;
      top:5.6rem;
      height: calc(100% - 5.6rem);
      box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
      z-index: 10;
    }
    .open-carrito-wrapper {
      bottom:1rem;
      right:0;
      width: fit-content;
    }
  }
</style>
