<template>
  <div class="opinions-wrapper">
    <div class="section-title-wrapper">
      OPINIONES
    </div>
    <div class="opinion-wrapper">
      <div class="text-wrapper">
        <div class="title-wrapper">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, quos in exercitationem odit vero labore fugiat ullam a earum nesciunt voluptate assumenda ratione eum necessitatibus asperiores repellendus mollitia molestiae quaerat.
        </div>
        <div class="name-wrapper">
          Joseph Johnson
        </div>
        <div class="date-wrapper">
          02-Enero-2023
        </div>
      </div>
    </div>
    <div class="opinion-wrapper">
    <div class="text-wrapper">
      <div class="title-wrapper">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, quos in exercitationem odit vero labore fugiat ullam a earum nesciunt voluptate assumenda ratione eum necessitatibus asperiores repellendus mollitia molestiae quaerat.
      </div>
      <div class="name-wrapper">
        Joseph Johnson
      </div>
      <div class="date-wrapper">
        02-Enero-2023
      </div>
    </div>
    </div>
    <div class="opinion-wrapper">
    <div class="text-wrapper">
      <div class="title-wrapper">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, quos in asperiores repellendus mollitia molestiae quaerat.
      </div>
      <div class="name-wrapper">
        Joseph Johnson
      </div>
      <div class="date-wrapper">
        02-Enero-2023
      </div>
    </div>
    </div>
    <div class="opinion-wrapper">
      <div class="text-wrapper">
        <div class="title-wrapper">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore maiores iste expedita esse, quibusdam odio temporibus. Expedita itaque laudantium velit quos dolores quas rem molestiae sint veniam! Neque, voluptates! Voluptatum!
        </div>
        <div class="name-wrapper">
          Joseph Johnson
        </div>
        <div class="date-wrapper">
          02-Enero-2023
        </div>
      </div>
    </div>
    <div class="opinion-wrapper">
      <div class="text-wrapper">
        <div class="title-wrapper">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae eum tenetur consequatur explicabo sapiente saepe architecto, fugit eaque numquam magnam magni ullam beatae distinctio nihuri!
        </div>
        <div class="name-wrapper">
          Joseph Johnson
        </div>
        <div class="date-wrapper">
          02-Enero-2023
        </div>
      </div>
    </div>
    <div class="opinion-wrapper">
      <div class="text-wrapper">
        <div class="title-wrapper">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, quos in asperiores repellendus mollitia molestiae quaerat.
        </div>
        <div class="name-wrapper">
          Joseph Johnson
        </div>
        <div class="date-wrapper">
          02-Enero-2023
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .section-title-wrapper {
    padding: 1rem;
    font-size: 1.4rem;
    font-style: italic;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
  }
  .opinions-wrapper {
    margin: 1rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    gap: 1rem;
  }
  .opinion-wrapper {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem;
    background-color: rgb(255, 253, 250);
    display: flex;
    max-width: 350px;
  }
  .title-wrapper {
    font-size: 1.2rem;
    padding-bottom: 1rem;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .name-wrapper {
    font-style: italic;
    color: #666;
    font-size: 0.9rem;
    text-align: right;
  }
  .date-wrapper {
    font-style: italic;
    color: #666;
    font-size: 0.8rem;
    text-align: right;
  }
  @media (width > 700px) {
    .section-title-wrapper {
      font-size: 1.8rem;
      padding-inline: 2rem;
    }
    .opinions-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding-block: 3rem;
    }
  }
</style>
