<template>
  <div class="title-container">
    Localizaciones
  </div>
  <div class="content-container">
    <div class="map-container" v-if="currentLocation==1">
      <iframe src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d14930.62182953905!2d-103.35874108516192!3d20.683591766943714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d20.675842764974245!2d-103.35593013038441!5e0!3m2!1ses!2smx!4v1712006123078!5m2!1ses!2smx" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="map-container" v-if="currentLocation==2">
      <iframe src="https://www.google.com/maps/embed?pb=!1m22!1m12!1m3!1d21108.441696901024!2d-103.43802580364249!3d20.731307248776623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m7!3e6!4m0!4m4!1s0x8428afaa94899e37%3A0x419d0e0a46eb0ae7!3m2!1d20.731814099999998!2d-103.4295775!5e0!3m2!1ses!2smx!4v1712008678162!5m2!1ses!2smx" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="map-container" v-if="currentLocation==3">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d641.022422109696!2d-103.35144318279991!3d20.692944617431458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b1f838fdf92d%3A0x88b7e2f16f47e162!2sFuente%20Alcalde!5e0!3m2!1ses!2smx!4v1712007329891!5m2!1ses!2smx" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="map-container" v-if="currentLocation==4">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.8121342949894!2d-103.38859328905548!3d20.63651298083686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428adbf1fb16917%3A0x80d9dcb31973fd93!2sPzla.%20Isla%20Milos%202238%2C%20Jardines%20de%20La%20Cruz%2C%2044950%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1712009176674!5m2!1ses!2smx" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="places-container">
      <div class="place-container" @click="goToLocation(1)">
        <div class="pin-container">
          <img src="@/assets/pin.png" alt="">
        </div>
        <div class="place-text-container">
          <div class="sucursal" :class="{'blue': currentLocation === 1}">
            Mi Cuernito Centro
          </div>
          <div class="direccion">
            C. Pedro Moreno, Americana, 44200
          </div>
          <div class="horario">
            Lunes a Jueves 09:00 am a 17:00 pm
          </div>
          <div class="telefono">
            Telefono: 3321325355
          </div>
        </div>
      </div>
      <div class="place-container" @click="goToLocation(2)">
        <div class="pin-container">
          <img src="@/assets/pin.png" alt="">
        </div>
        <div class="place-text-container">
          <div class="sucursal" :class="{'blue': currentLocation === 2}">
            Mi Cuernito Poniente
          </div>
          <div class="direccion">
            Av. del Servidor Público s/n, 45138 Zapopan, Jal.
          </div>
          <div class="horario">
            Lunes a Jueves 09:00 am a 17:00 pm
          </div>
          <div class="telefono">
            Telefono: 3321325355
          </div>
        </div>
      </div>
      <div class="place-container" @click="goToLocation(3)">
        <div class="pin-container">
          <img src="@/assets/pin.png" alt="">
        </div>
        <div class="place-text-container">
          <div class="sucursal" :class="{'blue': currentLocation === 3}">
            Mi Cuernito Alcalde
          </div>
          <div class="direccion">
            Artesanos, 44260 Guadalajara, Jal.
          </div>
          <div class="horario">
            Lunes a Jueves 09:00 am a 17:00 pm
          </div>
          <div class="telefono">
            Telefono: 3321325355
          </div>
        </div>
      </div>
      <div class="place-container" @click="goToLocation(4)">
        <div class="pin-container">
          <img src="@/assets/pin.png" alt="">
        </div>
        <div class="place-text-container">
          <div class="sucursal" :class="{'blue': currentLocation === 4}">
            Mi Cuernito Jardines
          </div>
          <div class="direccion">
            Pzla. Isla Milos 2238, Jardines de La Cruz, 44950 Guadalajara, Jal.
          </div>
          <div class="horario">
            Lunes a Jueves 09:00 am a 17:00 pm
          </div>
          <div class="telefono">
            Telefono: 3321325355
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .blue {
    color: rgb(2, 2, 199);
  }
  .title-container {
    padding-top: 6rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #47321d;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(255, 252, 249);
  }
  .content-container {
    padding-bottom: 1rem;
    background-color: rgb(255, 252, 249);
  }
  .map-container {
    display:flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 300px;
  }
  .pin-container {
    width: 2rem;
    padding-top: 0.4rem;
  }
  .pin-container img {
    width: 2rem;
  }
  .place-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding-block: 1rem;
  }
  .place-text-container {
    padding-right: 0.5rem;
  }
  .place-container:hover {
    cursor: pointer;
    background-color: #eee;
  }
  .horario {
    padding-block: 0.8rem;
    font-style: italic;
  }
  .direccion {
    max-width: 250px;
  }
  .sucursal {
    font-weight: bold;
  }
  @media (width > 500px) {
    .title-container {
      padding-top: 8rem;
    }
    .content-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 1rem;
    }
    .map-container {
      height: 500px;
      padding-right: 2rem;
    }
    .places-container {
      height: 500px;
      overflow-y: scroll;
      padding-left: 2rem;
    }
    .place-container {
      border-bottom: 1px solid rgb(132, 132, 132);
    }
  }
</style>

<script>
export default {
  data () {
    return {
      currentLocation: 1
    }
  },
  methods: {
    goToLocation (number) {
      this.currentLocation = number
    }
  }
}
</script>
